<template>
  <div class="orders-table">
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="productsOptions"
          v-model="productInfo.id"
          name="id"
          label="Продукт"
          :labelWidth="34"
      />
      <custom-input
          v-model="productInfo.name"
          :labelWidth="34"
          name="productName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="productInfo.count"
          name="productCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addProduct" class="btn btn-accent">Добавить</button>
    </div>
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="entitiesOptions"
          v-model="entityInfo.id"
          name="id"
          label="Сущность"
          :labelWidth="34"
      />
      <custom-input
          v-model="entityInfo.name"
          :labelWidth="34"
          name="entityName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="entityInfo.count"
          name="entityCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addEntity" class="btn btn-accent">Добавить</button>
    </div>
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="courseBitrixOptions"
          v-model="courseBitrixInfo.id"
          name="id"
          label="Курсы Bitrix"
          :labelWidth="34"
      />
      <custom-input
          v-model="courseBitrixInfo.name"
          :labelWidth="34"
          name="courseBitrixName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="courseBitrixInfo.count"
          name="courseBitrixCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addCourseBitrix" class="btn btn-accent">Добавить</button>
    </div>
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="courseMoodleOptions"
          v-model="courseMoodleInfo.id"
          name="id"
          label="Курсы Moodle"
          :labelWidth="34"
      />
      <custom-input
          v-model="courseMoodleInfo.name"
          :labelWidth="34"
          name="courseMoodleName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="courseMoodleInfo.count"
          name="courseMoodleCount"
          :labelWidth="34"
          :min="0"
      />
      <custom-input
          type="number"
          label="Срок действия"
          v-model="courseMoodleInfo.access_duration"
          name="courseMoodleCount"
          :labelWidth="50"
          :min="0"
      />
      <button @click="addCourseMoodle" class="btn btn-accent">Добавить</button>
    </div>
    <div class="subscription_block flex align-item-center" v-if="getRight('subscription')">
      <custom-select2
          :options="subscriptionOptions"
          v-model="subscriptionInfo.id"
          name="id"
          label="Подписка"
          :labelWidth="14"
      />
      <div class="action_button">
        <button @click="addSubscription" class="btn btn-accent">Добавить</button>
      </div>
    </div>
    <div class="order-table">
      <base-table
          v-if="rowData.length"
          :columns="orderFields"
          :rows="rowData"
      />
      <div v-else class="no_results">
        <p>Выберите продукт</p>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, reactive, ref, watch,} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import {useStore} from "vuex";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import reportTranslate from "@/mixins/dictionaries/reportTranslate.js";
import {notify} from "@kyvg/vue3-notification";
import BaseTable from "@/components/Tables/BaseTable.vue";
import useCheckRights from "@/mixins/useCheckRights";

export default {
  name: "orders-table",
  components: {
    BaseTable,
    CustomSelect2,
    VPagination,
    CustomInput,
  },
  props: {
    repurchaseData: {
      id: null,
      type: Array,
      product: null,
      entity: null,
      name: null,
      count: null,
      price: null
    }
  },
  setup(props, context) {
    const store = useStore();
    const { getRight } = useCheckRights();

    const relations = computed(() => store.state.relations.relations),
        productsList = computed(() => store.state.relations.productsList),
        entitiesList = computed(() => store.state.relations.entitiesList),
        subscriptionList = computed(() => store.state.subscription.subscriptionList);

    const positions = ref([]);
    const productsOptions = ref([]),
        entitiesOptions = ref([]),
        courseBitrixOptions = ref([]),
        courseMoodleOptions = ref([]),
        subscriptionOptions = ref([]);

    const productInfo = reactive({
      id: "",
      count: 0,
      productName: "",
      name: "",
      slug: ""
    });
    const subscriptionInfo = reactive({
      id: "",
      count: 1,
      productName: "",
      products: [],
      name: "",
    });

    const entityInfo = reactive({
      id: "",
      entityName: "",
      count: 0,
      name: "",
    });
    const courseBitrixInfo = reactive({
      id: "",
      courseBitrixName: "",
      count: 0,
      name: "",
      source: "",
    });
    const courseMoodleInfo = reactive({
      id: "",
      courseMoodleName: "",
      count: 0,
      name: "",
      source: "",
      access_duration: 180,
    });

    const rowData = computed(() => {
      if (props.repurchaseData) {
        positions.value = props.repurchaseData
        context.emit("change", positions.value);
      }
      return positions.value.map((position) => {
        return {
          id: position.id ?? null,
          product: position.product || "-",
          entity: position.entities || position.entity,
          name: position.name,
          count: position.count,
          type: position.type ?? '',
        };
      });
    });

    const deleteItem = (name) => {
      const isPositionIndex = positions.value.findIndex(
          (position) => position.name === name
      );
      positions.value.splice(isPositionIndex, 1);
      context.emit("change", positions.value);
    };

    const changeCount = (name, num) => {
      const isPositionIndex = positions.value.findIndex(
          (position) => position.name === name
      );
      const findedPosition = positions.value[isPositionIndex];
      const newCount = Number(findedPosition.count) + num;
      newCount <= 0
          ? positions.value.splice(isPositionIndex, 1)
          : (positions.value[isPositionIndex] = {
            ...findedPosition,
            count: newCount,
          });
      context.emit("change", positions.value);
    };

    const orderFields = [
      {
        field: "short",
        headerName: "Продукт",
        originField: "product",
      },
      {
        field: "entity",
        headerName: "Сущность",
      },
      {
        field: "short",
        headerName: "Название",
        originField: "name",
      },
      {
        field: "count",
        headerName: "Количество",
      },
      {
        field: "change_count",
        headerName: "",
        callback: changeCount,
      },
      {
        field: "delete_item",
        headerName: "",
        callback: deleteItem,
      },
    ];

    const addProduct = () => {
      if (
          !fieldValidation(productInfo.count, productInfo.id, productInfo.name)
      ) {
        return;
      }
      if (checkAndReplaceExist(productInfo.name, productInfo.count)) {
        return;
      }
      const productData = productInfo.id.split("_");
      const productEntities = relations.value
          .filter(
              (relation) =>
                  relation.site_entity_type === "product" &&
                  relation.site_entity_id == productData[1]
          )
          .map((relation) => relation.entity_name);
      const productObject = {
        id: productData[1],
        product: productInfo.productName,
        entities: productEntities,
        name: productInfo.name,
        price: productData[2],
        count: parseInt(productInfo.count, 10),
        slug: productInfo.slug,
        type: 'product',
      };

      positions.value.push(productObject);
      context.emit("change", positions.value);
    };

    const addCourseBitrix = () => {
      if (!fieldValidation(courseBitrixInfo.count, courseBitrixInfo.id, courseBitrixInfo.name)) {
        return;
      }
      if (checkAndReplaceExist(courseBitrixInfo.name, courseBitrixInfo.count)) {
        return;
      }
      const entityObject = {
        id: courseBitrixInfo.id,
        type: Object.keys(reportTranslate).includes(courseBitrixInfo.id)
            ? "test"
            : "course",
        product: null,
        entity: courseBitrixInfo.courseBitrixName,
        name: courseBitrixInfo.name,
        source: courseBitrixInfo.source || null,
        count: parseInt(courseBitrixInfo.count, 10),
      };
      positions.value.push(entityObject);
      context.emit("change", positions.value);
    };
    const addCourseMoodle = () => {
      if (!fieldValidation(courseMoodleInfo.count, courseMoodleInfo.id, courseMoodleInfo.name)) {
        return;
      }
      if (checkAndReplaceExist(courseMoodleInfo.name, courseMoodleInfo.count)) {
        return;
      }
      const entityObject = {
        id: courseMoodleInfo.id,
        type: Object.keys(reportTranslate).includes(courseMoodleInfo.id)
            ? "test"
            : "course",
        product: null,
        entity: courseMoodleInfo.courseMoodleName,
        name: courseMoodleInfo.name,
        source: courseMoodleInfo.source || null,
        count: parseInt(courseMoodleInfo.count, 10),
        access_duration: courseMoodleInfo.access_duration && parseInt(courseMoodleInfo.access_duration) !== 0 ? courseMoodleInfo.access_duration : null,
      };
      positions.value.push(entityObject);
      context.emit("change", positions.value);
    };
    const addEntity = () => {
      if (!fieldValidation(entityInfo.count, entityInfo.id, entityInfo.name)) {
        return;
      }
      if (checkAndReplaceExist(entityInfo.name, entityInfo.count)) {
        return;
      }
      const entityObject = {
        id: entityInfo.id,
        type: Object.keys(reportTranslate).includes(entityInfo.id)
            ? "test"
            : "course",
        product: null,
        entity: entityInfo.entityName,
        name: entityInfo.name,
        count: parseInt(entityInfo.count, 10),
      };
      positions.value.push(entityObject);
      context.emit("change", positions.value);
    };

    const addSubscription = () => {

      if (!subscriptionInfo.id) {
        notify({
          type: "warning",
          title: "Выберите подписку",
        });
        return;
      }

      const subscriptionData = subscriptionInfo.id.split("_");
      const subscriptionEntities = subscriptionInfo.products.map((product) => {
        return product.name + ' - ' + product.count;
      });

      const subscriptionObject = {
        id: subscriptionData[1],
        name: subscriptionInfo.name,
        entities: subscriptionEntities,
        product: subscriptionInfo.productName,
        price: subscriptionData[2],
        type: "subscription",
        count: 1,
      };

      if (positions.value.find((position) => position.type === 'subscription')) {
        notify({
          type: "warning",
          title: "Нельзя приобрести больше одной подписки",
        });
      } else {
        positions.value.push(subscriptionObject);
        context.emit("change", positions.value);
      }
    }

    const checkAndReplaceExist = (name, count) => {
      const isPositionIndex = positions.value.findIndex(
          (position) => position.name === name
      );
      if (isPositionIndex !== -1) {
        let findedPosition = positions.value[isPositionIndex];
        positions.value[isPositionIndex] = {
          ...findedPosition,
          count: Number(findedPosition.count) + Number(count),
        };
        return true;
      }
      return false;
    };

    const fieldValidation = (count, id, name) => {
      if (!count || !id || !name) {
        notify({
          type: "warning",
          title: "Не все поля заполнены",
        });
        return false;
      }
      if (count <= 0) {
        notify({
          type: "warning",
          title: "Некорректное количество",
        });
        return false;
      }
      return true;
    };

    onBeforeMount(() => {
      store.dispatch("relations/getProductsList").then(() => {
        productsOptions.value = productsList.value
            .filter((product) => product.type === 'product')
            .map((product) => {
            return {
              text: product.name,
              id: `${product.name}_${product.id}_${product.price}`,
              slug: product.slug
            };
          });
        });
      });
      store.dispatch("relations/getEntitiesList").then(() => {
        entitiesOptions.value = [
          ...Object.entries(entitiesList.value.tests).map((entity) => ({
            id: entity[0],
            text: entity[1],
          })),
          ...Object.entries(entitiesList.value.reference_profiles).map((entity) => ({
            id: entity[0],
            text: entity[1],
          })),
          ...Object.entries(entitiesList.value.trajectories).map((entity) => ({
            id: entity[0],
            text: entity[1],
          })),
        ];
        courseBitrixOptions.value = [
          ...entitiesList.value.courses.filter(course => course.source === "bitrix")
              .map((entity) => ({
                id: entity.id,
                source: entity.source || null,
                text: entity.title,
              })),
        ];
        courseMoodleOptions.value = [
          ...entitiesList.value.courses.filter(course => course.source === "moodle")
              .map((entity) => ({
                id: entity.id,
                source: entity.source || null,
                text: entity.title,
              })),
        ]
      });
      store.dispatch("relations/getRelations");
      store.dispatch("subscription/getSubscriptionList").then(() => {
        subscriptionOptions.value = subscriptionList.value
            .filter((product) => product.available)
            .map((product) => {
              return {
                text: product.title,
                type: 'subscription',
                id: `${product.title}_${product.id}_${product.price}`,
                products: product.products,
              };
            });
      });

    watch(
        () => courseMoodleInfo.id,
        () => {
          const courseMoodleName = courseMoodleOptions.value.find((course) => course.id.toString() === courseMoodleInfo.id).text;
          courseMoodleInfo.name = courseMoodleName;
          courseMoodleInfo.courseMoodleName = courseMoodleName;
          courseMoodleInfo.source = courseMoodleOptions.value.find((course) => course.id.toString() === courseMoodleInfo.id).source;
        }
    );
    watch(
        () => courseBitrixInfo.id,
        () => {
          const courseBitrixName = courseBitrixOptions.value.find((course) => course.id === courseBitrixInfo.id).text;
          courseBitrixInfo.name = courseBitrixName;
          courseBitrixInfo.courseBitrixName = courseBitrixName;
          courseBitrixInfo.source = courseBitrixOptions.value.find((course) => course.id === courseBitrixInfo.id).source;
        }
    );
    watch(
        () => entityInfo.id,
        () => {
          const entityName = entitiesOptions.value.find((entity) => entity.id === entityInfo.id).text;
          entityInfo.name = entityName;
          entityInfo.entityName = entityName;
        }
    );
    watch(
        () => productInfo.id,
        () => {
          const productName = productsOptions.value.find((product) => product.id === productInfo.id).text;
          productInfo.name = productName;
          productInfo.productName = productName;
          const slugData = productsOptions.value.find((product) => product.id === productInfo.id).slug;
          productInfo.slug = slugData;
        }
    );
    watch(
        () => subscriptionInfo.id,
        () => {
          const subscription = subscriptionOptions.value.find((product) => product.id === subscriptionInfo.id);
          subscriptionInfo.name = subscription.text;
          subscriptionInfo.productName = subscription.text;
          subscriptionInfo.products = subscription.products;
        }
    );

    return {
      entitiesOptions,
      productsOptions,
      rowData,

      positions,

      productInfo,
      entityInfo,

      addProduct,
      addEntity,
      courseBitrixOptions,
      courseBitrixInfo,
      addCourseBitrix,
      courseMoodleOptions,
      courseMoodleInfo,
      addCourseMoodle,

      orderFields,
      addSubscription,
      subscriptionOptions,
      subscriptionInfo,
      getRight
    };
  },
};
</script>

<style lang="scss">
.orders-table {
  .ag-body-viewport {
    min-height: 100px;
  }
}
</style>

<style lang="scss" scoped>
.adding-line {
  border-bottom: 2px solid var(--cream-color);
  padding: 5px 15px;

  .form-group:first-child {
    min-width: 350px;
  }

  .form-group {
    flex-basis: 26%;
    flex-grow: 1;
    margin-right: 10px;
  }

  .form-group:last-of-type {
    max-width: 270px;
  }

  &:last-child {
    border-top: none;
  }

  @media (max-width: 1469px) {
    display: grid;
    grid-template-columns: 50% 50%;

    .btn {
      margin-right: 15px;
    }

    .form-group:last-of-type {
      max-width: 100%;
    }

    .form-group:first-child {
      min-width: 100%;
    }
  }

  @media (max-width: 1145px) {
    grid-template-columns: 100%;
    padding-bottom: 15px;

    .btn {
      margin-top: 15px;
    }
  }
}

.order-table {
  padding: 5px 8px;
}

.no_results {
  text-align: center;
  padding: 40px 10px;
}

.subscription_block {
  padding: 5px 15px;
  column-gap: 10px;
  border-bottom: 2px solid var(--cream-color);
}

.subscription_block .form-group {
  width: 100%;
  justify-content: left;
}

@media (max-width: 950px) {
  .subscription_block {
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }

  .subscription_block .btn {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 40px;
  }

  .action_button {
    width: 100%;
    padding-right: 12px;
  }

  .subscription_block .form-group {
    margin-right: 10px;
  }
}

</style>
